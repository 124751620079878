// Libraries
import { defineStore } from "pinia";
import {
  getAuth,
  signOut,
  getRedirectResult,
  signInWithRedirect,
  GoogleAuthProvider,
  getAdditionalUserInfo,
  signInWithEmailAndPassword
} from "firebase/auth";
import { type FirebaseError } from "@firebase/app";

// Composables
import { useUser } from "@/composables/useUser";
import { useActiveCampaignTag } from "@/composables/useActiveCampaignTag";

// Services & Helpers
import { projectAuth } from "@/firebase/config";
import { storageSession } from "@/utils/storage";
import { bannerService } from "@/core/services/bannerService";
import { handleRuntimeErrors } from "@/helpers/error-handler";
import { locationService } from "@/core/services/location.service";

// Types
import { UserDetailsAdmin, UserStoreT, UserT, UserStatusType } from "@/@types/user";
import { apiService } from "@/core/services/apiService";

interface CustomError extends Error {
  code?: string;
  error?: { message?: string };
}

export const useUserStore = defineStore({
  id: "User",
  state: (): UserStoreT => ({
    token: "",
    userId: "",
    name: "",
    userInfos: {} as UserDetailsAdmin,
    error: "",
    loading: false,
    showLoadingModal: false,
    showWelcomeModal: false,
    hasAgreedToLatestTermsOfUse: false
  }),
  persist: true,
  getters: {},
  actions: {
    async loginByUsername(data: { email: string; password: string }, showError = true) {
      this.error = undefined;
      const auth = getAuth();
      const { userStatus } = useUser();

      try {
        storageSession.clear();
        const userCredential = await signInWithEmailAndPassword(auth, data.email, data.password);

        bannerService.enableBanner();
        const userInfos = await apiService.get<UserDetailsAdmin>({ endpoint: `users/${userCredential.user.uid}` });

        storageSession.setItem("user", {
          username: userCredential.user.displayName,
          email: userCredential.user.email,
          userId: userCredential.user.uid
        });

        if (userInfos.error || !userInfos.data) {
          this.error = userInfos.error;
          return;
        }

        this.token = userCredential.user.stsTokenManager.accessToken;
        this.name = userCredential.user.displayName!;
        this.userId = userCredential.user.uid;
        this.userInfos = { ...userInfos.data };

        userStatus.value = this.userInfos as unknown as UserStatusType;
        return userStatus.value;
      } catch (err: unknown) {
        const customErr = err as CustomError;
        const errorCode = customErr.code;

        const errorMessage =
          "TOO_MANY_ATTEMPTS_TRY_LATER : Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later.";

        if (customErr.error?.message === errorMessage) {
          this.error =
            "Parece que houve muitas tentativas de login. Tente novamente mais tarde ou entre em contato com nossa equipe para obter ajuda.";
        }

        if (errorCode === "auth/invalid-email") {
          this.error =
            "Ops! O e-mail inserido não parece válido. Por favor, verifique se está correto e tente novamente.";
        } else if (errorCode === "auth/wrong-password") {
          this.error =
            "Ops! Parece que o e-mail ou a senha estão incorretos. Verifique os dados digitados e tente novamente.";
        } else if (errorCode === "auth/user-not-found") {
          this.error =
            "Não localizamos um usuário com este e-mail. Verifique se digitou corretamente e tente novamente.";
        } else {
          this.error = "Ocorreu um erro ao entrar, tente novamente. Se o problema persistir, entre em contato conosco.";
        }

        if (showError) handleRuntimeErrors(this.error);

        return customErr;
      }
    },
    async signup(dataForm: {
      name: string;
      email: string;
      password: string;
      phone?: string;
      phoneLocalCode?: string;
      utmSource?: string;
      utmMedium?: string;
      utmCampaign?: string;
      utmTerm?: string;
      utmContent?: string;
    }) {
      try {
        const auth = await apiService.post({
          endpoint: `users`,
          body: { ...dataForm }
        });

        if (auth.error) {
          switch (auth.error as string) {
            case "auth/invalid-email":
              this.error = "Endereço de e-mail inválido, corrija e tente novamente.";
              break;
            case "auth/invalid-phone-number":
              this.error = "Número de whatsapp inválido, corrija e tente novamente.";
              break;
            case "auth/email-already-exists":
              this.error = "E-mail já cadastrado! Faça login em sua conta.";
              break;
            case "auth/phone-number-already-exists":
              this.error = "Número de whatsapp já cadastrado! Faça login em sua conta.";
              break;
            case "auth/invalid-password":
              this.error = "Senha não é segura. Insira uma nova senha e tente novamente.";
              break;
            case "missing-fields":
              this.error = "Campos incompletos.";
              break;
            case "email-already-exists":
              this.error = "E-mail já cadastrado! Faça login em sua conta.";
              break;
            default:
              this.error =
                "Ocorreu um erro ao cadastrar, tente novamente. Se o problema persistir, entre em contato conosco.";
          }
        }
      } catch (err) {
        handleRuntimeErrors(err);
      }
    },
    async signupByGoogle() {
      const provider = new GoogleAuthProvider();
      this.loading = true;

      try {
        await signInWithRedirect(projectAuth, provider);
        this.loading = false;
        return;
      } catch (err: unknown) {
        const error = err as CustomError;
        this.loading = false;
        handleRuntimeErrors(err);
        return error;
      }
    },
    async getGoogleSignupResult() {
      try {
        const res = await getRedirectResult(projectAuth);

        if (res) {
          // This gives you a Google Access Token. You can use it to access Google APIs.
          const credential = GoogleAuthProvider.credentialFromResult(res);
          const token = credential?.accessToken;

          // The signed-in user info.
          const user = res.user;
          const isNewUser = getAdditionalUserInfo(res)?.isNewUser;

          return {
            token: token,
            user: user,
            isNewUser: isNewUser
          };
        }
        return;
      } catch (err: unknown) {
        const error = err as { code: string; message: string; customData: { email: string } };
        const errorCode = error.code;
        const errorMessage = error.message;

        // The email of the user's account used.
        const email = error.customData.email;

        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(err as FirebaseError);

        console.log("Error code:" + errorCode);
        console.log("Error message:" + errorMessage);
        console.log("User e-mail:" + email);
        console.log("Auth credential type:" + credential);

        handleRuntimeErrors(err);

        return err;
      }
    },
    // sai do cache limpa
    async logout() {
      this.token = "";
      this.name = "";
      this.userInfos = {} as UserDetailsAdmin;
      this.userId = "";
      this.error = "";

      storageSession.clear();

      try {
        await signOut(getAuth());
      } catch (err) {
        handleRuntimeErrors(err);
      }
    },
    async updateUser(user: Partial<UserT>) {
      return await apiService.patch({ endpoint: "users", body: user });
    },
    async handleInitTrial(checkoutLink?: string, openOnNewTab?: boolean) {
      const { updateStatus } = useUser();
      const { triggerTag } = useActiveCampaignTag();

      const addTagActiveCampaign = async () => {
        if (checkoutLink && !openOnNewTab) await triggerTag("299");
      };

      this.loading = true;
      await Promise.all([
        await this.updateUser({ onboarding: { hasCompleted: true } }),
        await updateStatus(),
        await addTagActiveCampaign()
      ]);
      this.loading = false;

      if (checkoutLink) {
        if (openOnNewTab) {
          window.open(checkoutLink, "_blank", "noopener,noreferrer");
          return;
        }

        window.location.href = checkoutLink;
      }
    },
    async checkLatestTermsOfUseAgreement() {
      const { data } = await apiService.get<{ hasAgreedToLatestTermsOfUse: boolean }>({
        endpoint: "/terms-of-use/agreement/check"
      });
      this.hasAgreedToLatestTermsOfUse = data?.hasAgreedToLatestTermsOfUse ?? false;

      return data?.hasAgreedToLatestTermsOfUse;
    },
    async agreeToTermsOfUse(termsOfUseId: string) {
      let userIP = "Desconhecido";
      let userLocation = "Localização não disponível";

      try {
        const locationData = await locationService.getUserLocation();

        userIP = locationData.userIP;
        userLocation = locationData.userLocation;
      } catch (error) {
        console.error("Erro ao obter localização do usuário:", error);
      }

      try {
        await apiService.post({
          endpoint: "/terms-of-use/agreement",
          body: {
            termsOfUseId,
            userLocation,
            userIP
          }
        });

        this.hasAgreedToLatestTermsOfUse = true;
      } catch (error) {
        console.error("Erro ao concordar com os termos de uso:", error);
        throw error;
      }
    }
  }
});
