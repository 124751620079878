import { ErrorTable } from "./types";

export const defaultErrorMessages: Partial<ErrorTable> = {
  "anthropic-bad-request": {
    title: "Oops, confira os dados!",
    message: "Parece que algo não foi enviado corretamente para a Anthropic. Verifique e tente novamente."
  },
  "bad-request": {
    title: "Algo está faltando!",
    message: "Confira se todos os campos estão preenchidos corretamente e tente novamente."
  },
  "email-is-disposable": {
    title: "E-mail Temporário Detectado",
    message: "Use um e-mail permanente para continuar. Vamos lá!"
  },
  "document-is-too-long": {
    title: "Documento Grande Demais",
    message: "Reduza o tamanho do documento para prosseguir. Você consegue!"
  },
  "email-already-exists": {
    title: "E-mail Já em Uso",
    message: "Este e-mail já está registrado. Tente um diferente ou faça login."
  },
  "image-too-large": {
    title: "Imagem Grande Demais",
    message: "Reduza o tamanho da imagem para continuar. Vai valer a pena!"
  },
  "image-size": {
    title: "Erro de tamanho",
    message: "Error relacionado com as dimensões da imagem."
  },
  "invalid-ip-address": {
    title: "IP Inválido",
    message: "Confira e insira um IP válido para prosseguir."
  },
  "invalid-pdf-file-url": {
    title: "URL de PDF Inválida",
    message: "Verifique a URL do PDF e tente novamente."
  },
  "invalid-url": {
    title: "URL Inválida",
    message: "Parece que a URL não está certa. Dá uma olhadinha e tente de novo."
  },
  "invalid-youtube-video-url": {
    title: "URL do YouTube Inválida",
    message: "Confira a URL do YouTube e tente novamente. Estamos quase lá!"
  },
  "not-enough-content": {
    title: "Precisamos de Mais Conteúdo",
    message: "Adicione um pouco mais de conteúdo para prosseguir."
  },
  "openai-bad-request": {
    title: "Oops, confira os dados!",
    message: "Algo não foi enviado corretamente para a OpenAI. Verifique e tente novamente."
  },
  "openai-max-tokens": {
    title: "Limite de Tokens Excedido",
    message: "Reduza o tamanho do conteúdo e tente novamente."
  },
  "parent-folder-is-a-child-folder": {
    title: "Pasta Dentro de Pasta",
    message: "Escolha uma pasta diferente que não seja subpasta."
  },
  "phone-number-already-exists": {
    title: "Número Já Registrado",
    message: "Este número já está em uso. Por favor, verifique seu número ou tente outro."
  },
  "sd-invalid-prompt": {
    title: "Prompt Inválido",
    message: "Vamos ajustar o prompt para a geração de imagem e tentar novamente!"
  },
  "team-user-status-is-not-pending": {
    title: "Status do Usuário Incompatível",
    message: "O status deste usuário no time não está pendente. Verifique e tente novamente."
  },
  "user-already-requested-to-cancel": {
    title: "Cancelamento Já Solicitado",
    message: "Sua solicitação de cancelamento já foi registrada. Aguarde nossa resposta."
  },
  "user-credit-card-acquirer": {
    title: "Problema com Cartão de Crédito",
    message: "Verifique os detalhes do cartão de crédito e tente novamente."
  },
  "user-is-already-in-team": {
    title: "Usuário Já no Time",
    message: "Este usuário já faz parte do time. Vamos em frente!"
  },
  "video-is-too-long": {
    title: "Vídeo Muito Longo",
    message: "Reduza o vídeo para menos de 3 horas para prosseguir. Você consegue!"
  },
  "anthropic-authentication": {
    title: "Probleminha de Autenticação",
    message: "Houve um erro de autenticação com a Anthropic. Tente novamente ou contate o suporte."
  },
  "openai-authentication": {
    title: "Autenticação Necessária",
    message: "Precisamos autenticar novamente na OpenAI. Faça login e continue a jornada."
  },
  "token-has-expired": {
    title: "Sessão Expirada",
    message: "Sua sessão expirou. Faça login novamente para continuar."
  },
  "invalid-token": {
    title: "Token Inválido",
    message: "Seu token de autenticação parece inválido. Faça login novamente."
  },
  "token-not-found": {
    title: "Token Não Encontrado",
    message: "Não encontramos seu token de autenticação. Faça login para prosseguir."
  },
  "anthropic-forbidden": {
    title: "Acesso Negado na Anthropic",
    message: "Você não tem permissão para esta ação na Anthropic. Verifique suas credenciais."
  },
  "cant-change-own-data-in-team": {
    title: "Alteração de Dados Restrita",
    message: "Não é possível alterar seus próprios dados no time. Peça ajuda a um administrador."
  },
  "cant-change-status-from-pending": {
    title: "Mudança de Status Bloqueada",
    message: "O status 'pending' não pode ser alterado diretamente. Contate o administrador do time."
  },
  "cant-create-onboarding-copy": {
    title: "Criação de Copy Bloqueada",
    message: "Não é possível gerar copies no onboarding após sua conclusão."
  },
  "cant-delete-default-folder": {
    title: "Pasta Padrão Protegida",
    message: "A pasta padrão não pode ser apagada. Ela é essencial para a organização do projeto."
  },
  "cant-delete-default-project": {
    title: "Projeto Padrão Indeletável",
    message: "O projeto padrão é necessário. Crie ou use outros projetos para suas necessidades."
  },
  "cant-delete-news-or-warning-notification": {
    title: "Notificação Importante",
    message: "Notificações de notícias e avisos não podem ser apagadas. Elas são importantes!"
  },
  "cant-delete-yourself-from-team": {
    title: "Remoção do Time",
    message: "Não é possível se remover do time. Peça a um administrador para fazer isso."
  },
  "cant-update-default-project-name": {
    title: "Nome do Projeto Padrão Fixo",
    message: "O nome do projeto padrão não pode ser alterado. Crie um novo projeto com o nome desejado."
  },
  "cant-use-chat": {
    title: "Chat Indisponível",
    message: "O chat não está disponível para você no momento. Verifique suas permissões ou planos."
  },
  "cant-use-lp-vsl-pptx": {
    title: "Geração de PPTX Bloqueada",
    message: "A geração de PPTX não está disponível para você. Verifique seu plano ou permissões."
  },
  "cant-use-trial": {
    title: "Trial Indisponível",
    message: "O período de teste já foi utilizado ou não está disponível. Confira nossos planos!"
  },
  "forbidden-resource": {
    title: "Recurso Restrito",
    message: "Você não tem permissão para acessar este recurso. Verifique suas credenciais ou planos."
  },
  "not-enough-team-credits": {
    title: "Créditos Insuficientes no Time",
    message: "Seu time não tem créditos suficientes. Recarregue ou ajuste o uso para continuar."
  },
  "not-enough-team-image-credits": {
    title: "Créditos de Imagem Insuficientes no Time",
    message: "Adicione mais créditos de imagem ao time para continuar criando."
  },
  "not-enough-team-permission": {
    title: "Permissão de Time Insuficiente",
    message: "Esta ação requer permissões adicionais no time. Consulte um administrador."
  },
  "not-enough-team-plagiarism-credits": {
    title: "Créditos de Plágio Insuficientes no Time",
    message: "Precisamos de mais créditos para usar o detector de plágio. Hora de recarregar!"
  },
  "not-enough-user-credits": {
    title: "Créditos Insuficientes",
    message: "Você precisa de mais créditos para prosseguir. Vamos adicionar mais?"
  },
  "not-enough-user-image-credits": {
    title: "Créditos de Imagem Insuficientes",
    message: "Adicione créditos de imagem para continuar a sua jornada criativa."
  },
  "not-enough-user-plagiarism-credits": {
    title: "Créditos de Plágio Insuficientes",
    message: "Adicione créditos para usar o detector de plágio e garantir originalidade."
  },
  "openai-permission-denied": {
    title: "Permissão Negada na OpenAI",
    message: "Você não tem permissão para esta ação na OpenAI. Verifique suas credenciais."
  },
  "subscription-not-from-pagarme": {
    title: "Assinatura Não Identificada",
    message: "Sua assinatura não parece ser do Pagarme. Verifique e tente novamente."
  },
  "team-is-not-active": {
    title: "Time Inativo",
    message: "Seu time não está ativo no momento. Fale com o administrador para reativá-lo."
  },
  "team-is-not-in-trial": {
    title: "Trial do Time Finalizado",
    message: "Seu time não está mais no período de teste. Confira nossos planos disponíveis."
  },
  "team-user-is-not-active": {
    title: "Usuário do Time Inativo",
    message: "Este usuário do time não está ativo. Fale com o administrador para mais detalhes."
  },
  "team-users-limit-exceeded": {
    title: "Limite de Usuários Excedido",
    message: "Seu time atingiu o limite de usuários. Remova alguém ou atualize seu plano."
  },
  "user-is-not-team-admin": {
    title: "Administração do Time Necessária",
    message: "Essa ação requer status de administrador do time. Fale com o administrador."
  },
  "anthropic-not-found": {
    title: "Recurso Não Encontrado na Anthropic",
    message: "Não conseguimos encontrar o recurso solicitado na Anthropic. Verifique e tente novamente."
  },
  "openai-not-found": {
    title: "Recurso Não Encontrado na OpenAI",
    message: "O recurso solicitado não foi encontrado na OpenAI. Dê uma olhada e tente novamente."
  },
  "resource-not-found": {
    title: "Recurso Não Encontrado",
    message: "Parece que não conseguimos encontrar o recurso. Verifique se está correto e tente de novo."
  },
  "subscription-id-not-found": {
    title: "ID de Assinatura Não Encontrado",
    message: "Não encontramos uma assinatura com esse ID. Verifique e tente novamente."
  },
  "subscription-not-found": {
    title: "Assinatura Não Encontrada",
    message: "Não conseguimos localizar sua assinatura. Confira seus detalhes ou entre em contato."
  },
  "openai-unprocessable-entity": {
    title: "Requisição Não Processável na OpenAI",
    message: "Algo na sua requisição não pode ser processado pela OpenAI. Revise e tente novamente."
  },
  "anthropic-rate-limit": {
    title: "Limite de Requisições na Anthropic",
    message: "Atingimos o limite de requisições na Anthropic. Aguarde um pouco e tente novamente."
  },
  "openai-rate-limit": {
    title: "Limite de Requisições na OpenAI",
    message: "Atingimos o limite de requisições na OpenAI. Vamos tentar novamente em breve?"
  },
  "plan-change-last-five-minutes": {
    title: "Mudança Recente de Plano",
    message: "Você mudou de plano recentemente. Aguarde um pouco antes de tentar novamente."
  },
  "client-closed-request": {
    title: "Conexão Encerrada",
    message: "Parece que a solicitação foi cancelada. Se precisar, tente de novo."
  },
  "internal-server-error": {
    title: "Ops, Probleminha Interno",
    message: "Encontramos um erro interno. Estamos trabalhando para resolver isso. Tente mais tarde."
  },
  "anthropic-api-overloaded": {
    title: "API da Anthropic Sobrecarregada",
    message: "A API da Anthropic está sob muita demanda agora. Tente novamente mais tarde."
  },
  "anthropic-bad-gateway": {
    title: "Problema na Conexão com a Anthropic",
    message: "Houve um problema na conexão com a API da Anthropic. Vamos tentar novamente?"
  },
  "could-not-access-file-url": {
    title: "URL do Arquivo Inacessível",
    message: "Não conseguimos acessar a URL do arquivo. Verifique se está correta e tente novamente."
  },
  "could-not-access-pdf-url": {
    title: "URL do PDF Inacessível",
    message: "Não conseguimos acessar a URL do PDF. Verifique se está correta e tente novamente."
  },
  "openai-bad-gateway": {
    title: "Problema na Conexão com a OpenAI",
    message: "Houve um problema na conexão com a API da OpenAI. Vamos tentar novamente?"
  },
  "pinecone-bad-gateway": {
    title: "Problema na Conexão com a Pinecone",
    message: "Houve um problema na conexão com a API da Pinecone. Vamos tentar novamente?"
  },
  "stable-diffusion-timeout": {
    title: "Tempo Esgotado na Stable Diffusion",
    message: "A solicitação na Stable Diffusion demorou demais. Tente novamente em breve."
  },
  "upgrade-error-0000": {
    title: "Erro Desconhecido no Upgrade",
    message: "Encontramos um erro não identificado durante o upgrade. Tente novamente ou contate o suporte."
  },
  "upgrade-error-0001": {
    title: "Erro no Endereço Durante o Upgrade",
    message: "Houve um problema com o endereço fornecido no upgrade. Verifique e tente novamente."
  },
  "upgrade-error-0002": {
    title: "Saldo Insuficiente para o Upgrade",
    message: "Verifique o saldo para completar o upgrade. Se precisar de ajuda, estamos aqui."
  },
  "upgrade-error-0003": {
    title: "Limite Insuficiente para o Upgrade",
    message: "Seu limite atual não permite o upgrade. Verifique suas configurações financeiras."
  },
  "upgrade-error-0004": {
    title: "Upgrade Bloqueado pelo Banco",
    message: "O banco bloqueou o upgrade. Entre em contato com ele para mais informações."
  },
  "openai-model-overloaded": {
    title: "Modelo da OpenAI Sobrecarregado",
    message: "O modelo da OpenAI está com alta demanda agora. Tente novamente mais tarde."
  },
  "stable-diffusion-service-unavailable": {
    title: "Stable Diffusion Indisponível",
    message: "A Stable Diffusion está indisponível no momento. Vamos tentar novamente depois."
  },
  "invalid-email": {
    title: "E-mail Inválido",
    message: "Parece que o e-mail não é válido. Dê uma olhada e tente novamente."
  },
  "invalid-file-type": {
    title: "Tipo de Arquivo Não Suportado",
    message: "Use tipos de arquivo válidos: {{types}}. Confira e tente novamente."
  },
  "black-listed-domain": {
    title: "Domínio em Blacklist",
    message:
      "O domínio do e-mail está em nossa blacklist. Por favor, use um e-mail diferente ou entre em contato conosco."
  },
  "rate-limit": {
    title: "Limite de Requisições Excedido",
    message: "Você excedeu o limite de requisições deste endpoint. Aguarde um pouco e tente novamente."
  },
  "invalid-instagram-video-url": {
    title: "Link inválido",
    message: "O link do vídeo parece não estar correto. Verifique e tente de novo, por favor."
  },
  default: {
    title: "Ops! Algo de errado",
    message: "Encontramos um erro. Tente novamente mais tarde."
  }
};
