<script lang="ts" setup>
// Core
import { computed, onMounted, ref, watch, onUnmounted } from "vue";

// Librabies
import { TYPE } from "vue-toastification";
import { useElementHover, useElementSize } from "@vueuse/core";
import InlineSvg from "vue-inline-svg";

// Services & Helpers
import { PausableTimeout } from "@/utils/time";

// Assets
import errorIconUrl from "@/assets/icons/toast/error_icon.svg?url";
import successIconUrl from "@/assets/icons/toast/success_icon.svg?url";
import warningIconUrl from "@/assets/icons/toast/warning_icon.svg?url";
import closeIconUrl from "@/assets/icons/toast/close_icon.svg?url";

interface Props {
  type?: TYPE;
  title?: string;
  message?: string;
  buttonClose?: boolean;
  // NOTE: O campo duration teve que ser adicionado aqui, pois, por alguma razão o campo timeout
  //       no arquivo @/plugins/toast não está funcionando
  duration?: number;
}

const props = withDefaults(defineProps<Props>(), {
  type: TYPE.INFO,
  title: "Notificação",
  buttonClose: true,
  duration: 5000,
  message: undefined
});
const emit = defineEmits(["close-toast"]);

const toastRef = ref<HTMLElement | null>(null);
let timer: PausableTimeout;

const { height } = useElementSize(toastRef);
const isHovered = useElementHover(toastRef);

onMounted(() => {
  timer = new PausableTimeout(() => {
    emit("close-toast");
  }, props.duration);
});

onUnmounted(() => {
  timer.clear();
});

watch(
  () => isHovered.value,
  value => (value ? timer.pause() : timer.resume())
);

const toastStatus = computed(() => {
  switch (props.type) {
    case TYPE.ERROR:
      return { type: "error", icon: errorIconUrl };
    case TYPE.SUCCESS:
      return { type: "success", icon: successIconUrl };
    case TYPE.WARNING:
      return { type: "warning", icon: warningIconUrl };
    default:
      return { type: "success", icon: successIconUrl };
  }
});

const toastRole = computed(() => {
  return toastStatus.value.type === "success" ? "success" : "warning";
});
</script>

<template>
  <div
    ref="toastRef"
    :class="['custom-toast', `custom-toast--${toastStatus.type}`]"
    :role="toastRole"
    aria-live="polite"
  >
    <div :style="`height: ${height}px`" class="custom-toast__type">
      <div class="custom-toast__type__backdrop" />
      <inline-svg :src="toastStatus.icon" />
    </div>
    <div class="custom-toast__body">
      <h2 class="custom-toast__body__title">{{ props.title }}</h2>
      <p v-if="props.message" v-html="props.message" />
    </div>
    <button v-if="buttonClose" @click="emit('close-toast')">
      <inline-svg :src="closeIconUrl" />
    </button>
  </div>
</template>

<style lang="scss" scoped>
.custom-toast {
  background: $extra_light_gray_bg;
  box-shadow: $toast_box_shadow;
  border-radius: $br_sm;
  position: relative;
  min-height: $toast_min_height;
  max-width: $toast_width;
  min-width: $toast_width;
  @include flex(row, center, start);

  &.custom-toast--success .custom-toast__type .custom-toast__type__backdrop {
    background: $toast_success_bg_color;

    span:deep(svg path) {
      fill: $toast_success_icon_color;
    }
  }

  &.custom-toast--warning .custom-toast__type .custom-toast__type__backdrop {
    background: $toast_warning_bg_color;

    span:deep(svg path) {
      fill: $toast_warning_icon_color;
    }
  }

  &.custom-toast--error .custom-toast__type .custom-toast__type__backdrop {
    background: $toast_error_bg_color;

    span:deep(svg path) {
      fill: $toast_error_icon_color;
    }
  }

  &__type {
    min-width: $toast_type_backdrop_width;
    height: 100%;
    border-radius: $br_sm;
    overflow: hidden;
    position: relative;
    @include flex-center;

    &__backdrop {
      width: 100%;
      height: 100%;
      position: absolute;
      border-radius: 0px 70% 70% 0px;
      opacity: 0.3;
      z-index: $null_index;
    }

    > span {
      position: relative;
      z-index: $base_index;
    }
  }

  &__body {
    padding: $spacing_md_plus;
    @include flex(column, start, center);
    gap: $spacing_x_xs;

    h2 {
      font-size: $fs_sm;
      font-weight: $fw_bd;
      color: $text_neutral_gray;
    }

    p {
      font-size: $fs_sm;
      color: $text_neutral_gray;
    }
  }

  button {
    position: absolute;
    top: $toast_button_offset;
    right: $toast_button_offset;
    @include reset-button;
    @include flex-center;
    width: $toast_close_size;
    height: $toast_close_size;
    border-radius: 50%;
    background: $popover_light_color;

    box-shadow: $toast_button_box_shadow;
    &:hover {
      opacity: 0.5;
    }
  }
}
</style>
