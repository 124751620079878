// ApiService.ts
import axios, { AxiosInstance, AxiosError, AxiosRequestConfig, Method, AxiosResponse } from "axios";
import { projectAuth } from "../../firebase/config";
import { useRouter } from "vue-router";
import { useUserStore } from "@/store/user";
import { ApiErrorCode } from "@/@types/api";

interface ApiRequest<T = object> {
  endpoint: string;
  body?: T;
}

interface ApiError {
  // Código do erro
  error: string;
  // Mensagem do erro
  message?: string;
}

type Headers = AxiosRequestConfig["headers"];

export type ErrorStatus = ApiErrorCode | undefined;

export interface ApiResponse<T = unknown> {
  data: T | null;
  error?: ErrorStatus;
  errorData?: {
    error?: string;
    message?: string;
    code?: string;
    resource?: string;
  };
}

class ApiService {
  private axiosInstance: AxiosInstance;

  constructor(client?: AxiosInstance) {
    if (!client) {
      this.axiosInstance = axios.create({
        baseURL: import.meta.env.VITE_NEW_API_BASE_URL,
        headers: { "Content-Type": "application/json" }
      });
    } else {
      this.axiosInstance = client;
    }
    this.initializeInterceptors();
  }

  private initializeInterceptors() {
    this.axiosInstance.interceptors.request.use(
      async config => {
        const token = await this.getFirebaseToken();
        if (token) {
          config.headers["Authorization"] = `Bearer ${token}`;
        }
        return config;
      },
      error => Promise.reject(error)
    );

    this.axiosInstance.interceptors.response.use(
      response => response,
      (error: AxiosError) => {
        // TODO: Remover esse interceptor em caso de testes resultarem em erros, pois essa linha pode
        //       comprometer o funcionamento da aplicação.
        const router = useRouter();
        const userStore = useUserStore();

        if (error.status === 401) {
          userStore.logout();
          router.push("/login");
        }
        return Promise.reject(error);
      }
    );
  }

  private async getFirebaseToken(): Promise<string | null> {
    const user = projectAuth.currentUser;
    return user ? await user.getIdToken(true) : null;
  }

  async get<T, B = never>(request: ApiRequest<B>): Promise<ApiResponse<T>> {
    return this.callApi<T, B>({ ...request, method: "GET" });
  }

  async post<T, B = object>(request: ApiRequest<B>): Promise<ApiResponse<T>> {
    return this.callApi<T, B>({ ...request, method: "POST" });
  }

  async put<T, B = object>(request: ApiRequest<B>): Promise<ApiResponse<T>> {
    return this.callApi<T, B>({ ...request, method: "PUT" });
  }

  async patch<T, B = object>(request: ApiRequest<B>): Promise<ApiResponse<T>> {
    return this.callApi<T, B>({ ...request, method: "PATCH" });
  }

  async delete<T, B = object>(request: ApiRequest<B>): Promise<ApiResponse<T>> {
    return this.callApi<T, B>({ ...request, method: "DELETE" });
  }

  private async callApi<T, B = object>(
    request: ApiRequest<B> & { method: Method; headers?: Headers }
  ): Promise<ApiResponse<T>> {
    try {
      const config: AxiosRequestConfig = {
        method: request.method,
        url: request.endpoint,
        data: request.body
      };

      if (request.headers) config.headers = { ...config.headers, ...request.headers };

      const response: AxiosResponse<T> = await this.axiosInstance(config);
      return { data: response.data };
    } catch (error) {
      if (error instanceof AxiosError) {
        return { data: null, error: this.handleError(error), errorData: error.response?.data };
      }
      return { data: null, error: "unexpected-error" };
    }
  }

  private handleError(error: AxiosError<ApiError>): ApiErrorCode {
    if (error.response && error.response.data.error) {
      return error.response.data.error as ApiErrorCode;
    } else if (error.request) {
      return "network-error";
    } else {
      return "unexpected-error";
    }
  }

  // Expondo a instância do Axios para uso externo
  get axiosInst(): AxiosInstance {
    return this.axiosInstance;
  }
}

const apiService = new ApiService();

const apiMultipartService = new ApiService(
  axios.create({ baseURL: import.meta.env.VITE_NEW_API_BASE_URL, headers: { "Content-Type": "multipart/form-data" } })
);

export { apiService, apiMultipartService };
