// Libraries
import { pinia } from "@/main";
import { defineStore } from "pinia";
import { v4 as uuidv4 } from "uuid";

// Services & Helpers
import { getModelName } from "@/utils/copy";
import { phases, steps } from "@/utils/project";

// Services & Helpers
import { errorHandlerService } from "@/core/services/error";
import { handleRuntimeErrors } from "@/helpers/error-handler";
import { validateSourceErrors } from "@/core/services/error/customErrors";
import { apiMultipartService, apiService } from "@/core/services/apiService";

// Composables
import { useUser } from "@/composables/useUser";
import { toast } from "@/composables/useCustomToast";
import useStorage from "@/composables/useStorage";

// Stores
import { useTeamStore } from "./team";
import { useProjectStore } from "@/store/project";

// Types
import {
  ProjectData,
  ProjectPhase,
  ProjectStatus,
  ProjectCreationState,
  UpdateProjectDataType,
  ProjectBrandVoiceType,
  Reference,
  ExtractedData,
  ReferenceResponse,
  Project
} from "@/@types/project";
import { User } from "@/@types/team";
import { Persona } from "@/@types/persona";
import { ITag, TagColorValue } from "@/@types/tag-input";

export const stringsToObjectArray = (strings?: string[] | string): { label: string; checked: boolean }[] => {
  if (Array.isArray(strings)) {
    return strings.map(str => ({ label: str, checked: true }));
  } else {
    if (strings) {
      return [{ label: strings, checked: true }];
    } else {
      return [];
    }
  }
};

export const objectArrayToStrings = (objects: { label: string; checked: boolean }[]): string[] => {
  return objects.filter(obj => obj.checked).map(obj => obj.label);
};

export const getAgeRangeFromSlider = (sliderValues: [number, number]) => {
  return `${sliderValues[0]}-${sliderValues[1]}${sliderValues[1] >= 65 && "+"}`;
};

const splitStringInTwo = (str: string, separator: string) => {
  const parts = str.split(separator, 2);

  if (parts.length < 2) return null;

  return parts;
};

export const stringToNumberArray = (str: string): [number, number] => {
  if (!str) return [25, 45];
  const parts = splitStringInTwo(str, "-");
  if (!parts?.[0] || !parts?.[1]) return [25, 45];

  const transformed = parts.map(part => {
    let numberPart = parseFloat(part);

    if (isNaN(numberPart) || !part) {
      return 25;
    }

    if (numberPart < 13) {
      numberPart = 13;
    } else if (numberPart > 65) {
      numberPart = 65;
    }

    return numberPart;
  });

  return [transformed[0] ?? 0, transformed[1] ?? 0];
};

export const useProjectCreationStore = defineStore("projectCreation", {
  state: (): ProjectCreationState => ({
    currentStep: 0,
    rejectedStep: null,
    lastStep: 0,
    extractedDataId: null,
    maxStep: 0,
    projectExtractedData: null,
    projectData: null,
    references: [],
    disableSkipModal: false,
    stepMiddleware: null,
    isProjectCreated: false,
    showWarningModal: false,
    checkExtractionCount: 0,
    currentReferenceIndex: 0,
    hasProjectJobFailed: false,
    hasReferenceJobFailed: false,
    hasAudienceJobFailed: false,
    audience: [],
    warningModalAction: () => {},
    type: {
      value: null,
      content: null,
      errorTxt: undefined,
      valid: true,
      status: "disabled",
      validate: function (forcedValue?: boolean) {
        if (forcedValue !== undefined) {
          this.valid = forcedValue;
        } else {
          if (this.value === "link") {
            const protocolAndUrlPattern = new RegExp(
              "^(https?:\\/\\/)?([a-z0-9]+([-\\.]{1}[a-z0-9]+)*\\.[a-z]{2,7})(\\/.*)?$"
            );
            const videoPattern = new RegExp("(youtube\\.com)", "i");
            const googleDocsPattern = new RegExp("(docs\\.google\\.com)", "i");
            const loginNeededPattern = new RegExp(
              "(notion\\.so|facebook\\.com|asana\\.com|app\\.asana\\.com|trello\\.com|drive\\.google\\.com)",
              "i"
            );

            const isUrl = protocolAndUrlPattern.test(this.content as string);
            const isVideoUrl = videoPattern.test(this.content as string);
            const isGoogleDocsUrl = googleDocsPattern.test(this.content as string);
            const isLoginNeededUrl = loginNeededPattern.test(this.content as string);

            if (isVideoUrl) {
              this.errorTxt = "Por favor, selecione a opção Vídeo / Áudio para enviar um vídeo.";
              this.valid = false;
              return;
            }

            if (isGoogleDocsUrl) {
              this.errorTxt = `Oops, não conseguimos ler documentos privados. Baixe este conteúdo como .PDF ou .DOC e faça upload na opção "Arquivo de texto".`;
              this.valid = false;
              return;
            }

            if (isLoginNeededUrl) {
              this.errorTxt = "Oops, não conseguimos ler links que exigem login ainda. Por favor, tente outro link.";
              this.valid = false;
              return;
            }

            if (!isUrl) {
              this.errorTxt = "Insira um link válido.";
              this.valid = false;
              return;
            }

            this.errorTxt = undefined;
            this.valid = true;
          } else if (this.value === "video-link") {
            const videoLinkRegex = new RegExp(
              "^(?:https?://)?(?:www\\.)?(?:youtube\\.com/watch\\?v=|youtu\\.be/|youtube\\.com/embed/|youtube\\.com/v/|youtube\\.com/user/[^/]+#p/u/[^/]+/|youtube\\.com/s[^/]+/|m\\.youtube\\.com/watch\\?v=|youtube\\.com/shorts/[a-zA-Z0-9_-]+|youtube\\.com/live/[a-zA-Z0-9_-]+)([a-zA-Z0-9_-]{11})?|" +
                "(?:https?://)?(?:www\\.)?(?:instagram\\.com/(?:[^/]+/)?p/|instagr\\.am/p/|instagram\\.com/(?:[^/]+/)?tv/|instagr\\.am/tv/|instagram\\.com/(?:[^/]+/)?reel/|instagr\\.am/reel/|instagram\\.com/(?:[^/]+/)?reels/)([a-zA-Z0-9._-]+)|" +
                "(?:https?://)?(?:www\\.)?(?:tiktok\\.com/@[^/]+/video/|tiktok\\.com/v/)([a-zA-Z0-9_-]+)(?:\\?.*)?$"
            );

            if (videoLinkRegex.test(this.content as string)) {
              this.errorTxt = undefined;
              this.valid = true;
            } else {
              this.errorTxt = "Insira um link válido.";
              this.valid = false;
            }
          }
        }
      }
    },
    hiddenFields: {
      gender: "",
      ageRange: [25, 45],
      characteristics: "",
      interests: "",
      consciousnessLevel: "",
      avatar: {
        file: "",
        preview: ""
      },
      summary: "",
      positiveWords: "",
      negativeWords: "",
      coverFile: null
    },
    fields: {
      name: {
        name: "Nome",
        display: "value",
        phase: 0,
        status: "disabled",
        value: "",
        step: 1,
        tooltip: {
          default: "Nome do projeto"
        },
        valid: true,
        validate: function (forcedValue?: boolean) {
          if (forcedValue !== undefined) {
            this.valid = forcedValue;
          } else {
            if (!this.value || this.value === "") {
              this.valid = false;
            } else {
              this.valid = true;
              this.notAvailable = false;
            }
          }
        },
        notAvailable: false
      },
      description: {
        name: "Descrição",
        phase: 0,
        status: "disabled",
        value: "",
        step: 2,
        tooltip: {
          default: null,
          pending: "Sem a descrição não sei do que se trata esse projeto. Clique para cadastrar!"
        }
      },
      differentials: {
        name: "Diferenciais",
        phase: 0,
        status: "disabled",
        value: "",
        step: 2,
        tooltip: {
          default: null,
          pending: "Ainda não consigo saber os diferenciais do projeto. Clique para cadastrar."
        }
      },
      brandVoices: {
        name: "Voz da marca",
        phase: 1,
        status: "disabled",
        value: [],
        step: 3,
        tooltip: {
          default: null,
          pending: "Ainda não sei qual a voz da sua marca. Clique para cadastrar."
        }
      },
      tones: {
        name: "Tom",
        phase: 1,
        status: "disabled",
        value: [],
        step: 3,
        tooltip: {
          default: null,
          pending: "Ainda não sei qual a tom que você usa em seus conteúdos. Clique para cadastrar."
        }
      },
      languageStyles: {
        name: "Linguagem",
        phase: 1,
        status: "disabled",
        value: [],
        step: 3,
        tooltip: {
          default: null,
          pending: "Ainda não sei qual o tipo linguagem usa para se comunicar com os clientes. Clique para cadastrar."
        }
      },
      perspectives: {
        name: "Perspectiva",
        phase: 1,
        status: "disabled",
        value: [],
        step: 3,
        tooltip: {
          default: null,
          pending: "Ainda não sei qual a perspectiva que você usa nas suas comunicações. Clique para cadastrar."
        }
      },
      personaName: {
        name: "Nome da persona",
        display: "value",
        phase: 2,
        status: "disabled",
        value: "",
        step: 4,
        tooltip: {
          default: "Nome da persona",
          pending:
            "Me apresente a persona e suas característica para poder criar os melhores resultados. Clique para cadastrar!"
        }
      },
      references: {
        name: "Referências",
        display: "value",
        phase: 3,
        status: "disabled",
        value: [],
        step: 5,
        tooltip: {
          default: "Clique para conferir",
          pending:
            "Eu aprendo muito com as referências. Dê exemplos das suas copies para melhorar meu treinamento. Clique para cadastrar!"
        }
      },
      language: {
        name: "Idioma",
        phase: 4,
        status: "disabled",
        value: "Portuguese (BR)",
        step: 6,
        tooltip: {
          default: null
        }
      },
      banner: {
        name: "Capa",
        phase: 4,
        status: "disabled",
        value: "",
        step: 7,
        tooltip: {
          default: "Clique para conferir",
          pending: "Imagem de capa não definida"
        }
      },
      tags: {
        name: "Tags",
        phase: 4,
        status: "disabled",
        value: [],
        step: 7,
        tooltip: {
          default: null,
          pending: "Nenhuma tag foi adicionada ao projeto"
        }
      },
      members: {
        name: "Membros",
        phase: 4,
        status: "disabled",
        value: [],
        step: 7,
        tooltip: {
          default: null,
          pending: "Você é o único membro do projeto! Informe os demais e-mails dos membros que devem ter acesso."
        }
      }
    },
    linkValidationLoading: false,
    linkExtractionLoading: false,
    projectLoading: false,
    referenceLoading: false,
    linkExtractionLoadingPercent: 0,
    oldProjectname: null,
    PROJECT_NAME_STORAGE_KEY: "project-name-field"
  }),
  actions: {
    changeCurrentStep(step: number, validation = true) {
      if (step === this.currentStep) return;
      if (validation && this.stepMiddleware && !this.stepMiddleware()) {
        this.rejectedStep = step;

        if (!this.disableSkipModal) {
          return;
        }
      }
      this.lastStep = this.currentStep;
      this.currentStep = step;
      this.maxStep = Math.max(this.maxStep, this.currentStep);
      this.stepMiddleware = null;
      this.rejectedStep = null;
    },
    changeToRejectedStep() {
      this.changeCurrentStep(this.rejectedStep || this.currentStep, false);
    },
    nextStep(validation = false) {
      this.changeCurrentStep(this.currentStep + 1, validation);
    },
    prevStep() {
      this.lastStep = this.currentStep;
      this.currentStep--;
    },
    updateFieldStatus(field: string, status: "completed" | "pending" | "disabled") {
      this.fields[field as keyof typeof this.fields].status = status;
    },
    startFieldStatus(field: string) {
      if (this.fields[field as keyof typeof this.fields].status === "disabled") {
        this.updateFieldStatus(field, "pending");
      }
    },
    validateField(fieldName: string) {
      const field = this.fields[fieldName];
      if (field.validation && field.validation(field.value)) {
        return true;
      }
      if (Array.isArray(field.value) && !field.value.length) return false;
      if (!!field.value) return true;
      return false;
    },
    setStepMiddleware(middleware: () => boolean) {
      this.stepMiddleware = middleware;
    },
    async validateLink(
      teamId: string,
      reference = false,
      createReferences = false,
      updateProject?: boolean,
      onboarding = false,
      showToast = true
    ) {
      this.hasAudienceJobFailed = false;
      this.hasProjectJobFailed = false;
      this.hasReferenceJobFailed = false;
      this.linkValidationLoading = true;
      let formattedUrl: string = "";

      const treatError = (error: string) => {
        this.linkValidationLoading = false;
        this.hasProjectJobFailed = true;
        this.hasReferenceJobFailed = true;
        this.type.errorTxt = "Não foi possível extrair as informações deste link. Por favor, tente inserir outro.";
        this.type.validate(false);
        this.type.status = "disabled";
        errorHandlerService.handleError(error, validateSourceErrors, undefined, showToast);
        return;
      };

      if (
        !(this.type.content as string)?.startsWith("http://") &&
        !(this.type.content as string)?.startsWith("https://")
      ) {
        formattedUrl = "https://" + this.type.content;
      } else {
        formattedUrl = this.type.content as string;
      }

      const body = {
        url: formattedUrl,
        onboarding: onboarding,
        projectData: {
          run: !reference,
          sendNotification: false
        },
        audiences: {
          run: true,
          sendNotification: false
        }
      };

      if (reference) {
        body.audiences = {
          run: false,
          sendNotification: false
        };
      }

      const res = await apiService.post<{ id: string }>({
        endpoint: `teams/${teamId}/extract-data/urls/v2`,
        body
      });

      this.linkValidationLoading = false;

      if (res.error) {
        treatError(res.error);
        return;
      }

      if (!res.data) return;

      this.extractedDataId = res.data.id;

      if (!reference) {
        await this.checkLinkResponse(teamId, res.data.id, updateProject, showToast);
      }

      if (reference || onboarding) {
        await this.checkLinkResponseReferences(teamId, res.data.id, createReferences, onboarding, showToast);
      }
    },
    async validateVideo(teamId: string, reference = false, createReferences = false, updateProject?: boolean) {
      this.linkValidationLoading = true;

      const treatError = (error?: string) => {
        if (!error) {
          handleRuntimeErrors(error);
        } else {
          errorHandlerService.handleError(error, validateSourceErrors);
        }

        this.type.valid = false;
        this.linkValidationLoading = false;
        this.type.status = "disabled";

        if (error === "invalid-instagram-video-url") {
          this.type.errorTxt = "Não identificamos um vídeo do Instagram neste link. ";
        } else if (error === "invalid-tiktok-video-url") {
          this.type.errorTxt = "Não identificamos um vídeo do Tiktok neste link.";
        } else {
          this.type.errorTxt = "Ops, algo deu errado.";
        }
      };

      try {
        const content = this.type.content;

        const body: Record<string, unknown> | null = {
          v2: true,
          projectData: JSON.stringify({
            run: !reference,
            sendNotification: false
          }),
          reference: JSON.stringify({
            run: true,
            sendNotification: false
          }),
          audiences: JSON.stringify({
            run: true,
            sendNotification: false
          })
        };

        if (reference) {
          body.audiences = JSON.stringify({
            run: false,
            sendNotification: false
          });
        }

        if (this.type.value === "video") {
          body.file = content as File;
        } else {
          body.url = content as string;
        }

        const res = await apiMultipartService.post<{ id: string }>({
          endpoint: `teams/${teamId}/extract-data/audios`,
          body
        });

        this.linkValidationLoading = false;

        if (res.error || !res.data) {
          treatError(res.error);
          return;
        }

        this.extractedDataId = res.data.id;

        if (!reference) {
          await this.checkLinkResponse(teamId, this.extractedDataId!, updateProject);
        } else {
          await this.checkLinkResponseReferences(teamId, this.extractedDataId!, createReferences);
        }
      } catch (e) {
        treatError();
      }
    },
    async validateFile(teamId: string, reference = false, createReferences = false, updateProject?: boolean) {
      this.linkValidationLoading = true;

      const treatError = (error?: string) => {
        this.linkValidationLoading = false;
        this.type.errorTxt = "Ops, algo deu errado.";
        this.type.validate(false);
        this.type.status = "disabled";
        const validDocumentsTypes = ".doc, .docx, .text e .pdf";

        const errorMessages = {
          "invalid-file-type": {
            title: "Tipo de arquivo não suportado.",
            message: "Use tipos de arquivo válidos: {{types}}. Confira e tente novamente."
          },
          "document-is-too-long": {
            title: "Arquivo muito grande.",
            message: "Vamos tentar enviar outro arquivo com menos palavras?"
          }
        };

        if (error === "invalid-file-type") {
          errorHandlerService.handleError(error, errorMessages, { types: validDocumentsTypes });
        } else if (error === "document-is-too-long") {
          this.type.errorTxt = "Oops, tamanho do arquivo não suportado. Aceitamos arquivos com até 150.000 palavras.";
          errorHandlerService.handleError(error, errorMessages);
        } else {
          errorHandlerService.handleError(error, errorMessages);
        }
        return;
      };

      try {
        const body: { file: File } | Record<string, unknown> | null = {
          v2: true,
          file: this.type.content as File,
          projectData: JSON.stringify({
            run: !reference,
            sendNotification: false
          }),
          reference: JSON.stringify({
            run: true,
            sendNotification: false
          }),
          audiences: JSON.stringify({
            run: true,
            sendNotification: false
          })
        };

        if (reference) {
          body.audiences = JSON.stringify({
            run: false,
            sendNotification: false
          });
        }

        const res = await apiMultipartService.post<{ id: string }>({
          endpoint: `teams/${teamId}/extract-data/documents`,
          body
        });

        this.linkValidationLoading = false;

        if (res.error || !res.data) {
          treatError(res.error);
          return;
        }

        this.extractedDataId = res.data.id;

        if (!reference) {
          await this.checkLinkResponse(teamId, this.extractedDataId!, updateProject);
        } else {
          await this.checkLinkResponseReferences(teamId, this.extractedDataId!, createReferences);
        }
      } catch (e) {
        const errorCode = (e as { response: { data: { error: string } } }).response?.data?.error;
        treatError(errorCode || (e as string));
      }
    },
    validateExtractedFields() {
      if (
        (!this.fields.languageStyles.value?.length &&
          !this.fields.perspectives.value?.length &&
          !this.fields.brandVoices.value?.length &&
          !this.fields.tones.value?.length) ||
        !this.fields.description.value ||
        !this.fields.differentials.value
      ) {
        this.hasProjectJobFailed = true;
      }
    },
    clearCreationFields() {
      this.fields.description.value = "";
      this.fields.differentials.value = "";
      this.fields.languageStyles.value = [];
      this.fields.perspectives.value = [];
      this.fields.brandVoices.value = [];
      this.fields.tones.value = [];
      this.hiddenFields.positiveWords = "";
      this.hiddenFields.negativeWords = "";
      this.audience = [];
      this.validateExtractedFields();
    },
    async checkLinkResponse(teamId: string, linkId: string, updateProject?: boolean, showToast = true) {
      this.changeCurrentStep(1);
      this.checkExtractionCount = 0;
      this.linkExtractionLoading = true;
      this.linkExtractionLoadingPercent = 0;
      this.hasProjectJobFailed = false;
      this.hasReferenceJobFailed = false;
      this.hasAudienceJobFailed = false;

      const treatError = (error?: string) => {
        let msg = "Algo deu errado.";

        if (error === "rate-limit" && showToast) {
          handleRuntimeErrors("Limite de tempo atingido ao tentar gerar conteúdo.", {
            message: "Limite de tempo atingido ao tentar gerar conteúdo.",
            showToast: showToast
          });
        }

        if (error === "resource-not-found") {
          msg = "Não foi possível extrair as informações da fonte fornecida.";
        }

        this.linkExtractionLoading = false;
        this.hasProjectJobFailed = true;
        this.hasReferenceJobFailed = true;

        this.clearCreationFields();

        handleRuntimeErrors(msg, { showToast: showToast });
      };

      const isVideoUrl = /tiktok|instagram/i.test(this.type.content as string);

      const limit = this.type.value?.includes("video") ? 100 : isVideoUrl ? 50 : 15;
      const intervalTime = this.type.value?.includes("video") || isVideoUrl ? 30000 : 5000;

      const handleExtractionError = (error?: string) => {
        clearInterval(checkInterval);
        this.hasProjectJobFailed = true;
        this.hasReferenceJobFailed = true;
        this.hasAudienceJobFailed = true;

        const defaultErrorMsg = this.projectExtractedData?.jobs.projectData?.error?.replace("Error: ", "") ?? "";
        const projectExtractionType = this.type.value;

        let errorMsgTitle: string = "Algo deu errado.";
        let errorMsgText: string = "Não foi possível extrair as informações da fonte fornecida.";

        switch (projectExtractionType) {
          case "link":
            errorMsgTitle = "Link inválido";
            errorMsgText = defaultErrorMsg + " <strong>Por favor, tente novamente com outro link.</strong>";
            if (defaultErrorMsg === "private-resource" && this.projectExtractedData?.source.includes("instagram")) {
              errorMsgTitle = "Perfil do Instagram privado!";
              errorMsgText =
                "Para continuar, precisamos de um perfil público. Por favor, tente novamente com outro link.";
            }
            break;
          case "video-link":
            errorMsgTitle = "Link inválido";
            errorMsgText = defaultErrorMsg + " <strong>Por favor, tente novamente com outro link.</strong>";
            if (defaultErrorMsg === "private-resource" && this.projectExtractedData?.source.includes("instagram")) {
              errorMsgTitle = "Perfil do Instagram privado!";
              errorMsgText =
                "Para continuar, precisamos de um perfil público. Por favor, tente novamente com outro link.";
            }
            break;
          case "txt":
            errorMsgTitle = "Arquivo inválido";
            errorMsgText = defaultErrorMsg + " <strong>Por favor, tente novamente com outro arquivo.</strong>";
            break;
          case "video":
            errorMsgTitle = "Arquivo inválido";
            errorMsgText = defaultErrorMsg + " <strong>Por favor, tente novamente com outro arquivo.</strong>";
            break;
          default:
            errorMsgTitle = "Algo deu errado.";
            errorMsgText = "Não foi possível extrair as informações da fonte fornecida.";
            break;
        }

        if (error === "ResourceNotFoundError: The following resource was not found: Instagram url") {
          errorMsgTitle = "Link do Instagram inválido";
          errorMsgText = "O link do vídeo do Instagram parece não estar correto. Verifique e tente de novo, por favor.";
        }

        if (showToast) {
          handleRuntimeErrors(errorMsgText, {
            title: errorMsgTitle,
            message: errorMsgText
          });
        }

        this.type.valid = false;
        this.type.errorTxt =
          "Oops, não foi possível extrair os dados do " + this.type.value === "txt" || this.type.value === "video"
            ? "arquivo."
            : "link";

        if (defaultErrorMsg === "private-resource" && this.projectExtractedData?.source.includes("instagram")) {
          this.type.errorTxt = "Não é possível treinar um projeto com um perfil privado.";
        }

        this.disableSkipModal = true;
        this.changeCurrentStep(0);
      };

      const checkInterval = setInterval(async () => {
        if (this.checkExtractionCount === limit) {
          clearInterval(checkInterval);
          return;
        }

        const res = await apiService.get<ProjectData>({ endpoint: `teams/${teamId}/extract-data/${linkId}` });

        if (res.error) {
          clearInterval(checkInterval);
          treatError(res.error);
          return;
        }

        if (!res.data) {
          clearInterval(checkInterval);
          this.linkExtractionLoading = false;
          return;
        }

        const { jobs } = res.data;

        const failedJob = Object.values(jobs).find(job => job && job.status === "errored");

        if (failedJob) handleExtractionError(failedJob.error);

        const completed = Object.values(jobs).every(job => job && job.status !== "pending");

        if (completed) {
          clearInterval(checkInterval);
          this.linkExtractionLoading = false;

          const {
            projectData: { dataExtracted: extractedProject },
            audiences: { dataExtracted: extractedAudiences },
            references: { dataExtracted: extractedReferences }
          } = res.data.jobs;

          if (updateProject) {
            const projectStore = useProjectStore(pinia);

            projectStore.update.brandVoice.brandVoices = stringsToObjectArray(extractedProject.brandVoice.brandVoices);
            projectStore.update.brandVoice.perspectives = stringsToObjectArray(
              extractedProject.brandVoice.perspectives
            );
            projectStore.update.brandVoice.languages = stringsToObjectArray(extractedProject.brandVoice.languages);
            projectStore.update.brandVoice.tones = stringsToObjectArray(extractedProject.brandVoice.tones);
            this.audience = extractedAudiences.map(audience => ({ ...audience, id: uuidv4(), default: true }));

            this.validateExtractedFields();
          } else {
            this.fields.description.value = extractedProject.description || "";
            this.fields.differentials.value = Array.isArray(extractedProject.differentials)
              ? extractedProject.differentials.join("\n")
              : extractedProject.differentials || "";
            this.fields.languageStyles.value = stringsToObjectArray(extractedProject.brandVoice?.languages || []);
            this.fields.perspectives.value = stringsToObjectArray(extractedProject.brandVoice?.perspectives || []);
            this.fields.brandVoices.value = stringsToObjectArray(extractedProject.brandVoice?.brandVoices || []);
            this.fields.tones.value = stringsToObjectArray(extractedProject.brandVoice?.tones || []);
            this.hiddenFields.positiveWords = extractedProject.mustHave.join(", ");
            this.hiddenFields.negativeWords = extractedProject.mustNotHave.join(", ");
            this.audience = extractedAudiences.map(audience => ({ ...audience, id: uuidv4(), default: true }));

            if (extractedReferences) {
              this.addReferencesFromExtractedData(extractedReferences);
            }

            // NOTE: A linha abaixo realiza a validação de todos os campos extraídos, isso apenas tem efeito no onboarding
            this.validateExtractedFields();

            const { getStoredItem } = useStorage();

            const name = getStoredItem(this.PROJECT_NAME_STORAGE_KEY);

            if (!name) {
              this.changeCurrentStep(1);
              return;
            }

            const { userStatus } = useUser();

            if (name)
              await this.createProject({
                name,
                teamId: userStatus.value?.activeTeam.id,
                users: [userStatus.value?.id]
              });
          }
        }

        this.checkExtractionCount++;
      }, intervalTime);
    },
    async checkLinkResponseReferences(
      teamId: string,
      linkId: string,
      createReferences = false,
      onboarding = false,
      showToast = true
    ) {
      this.hasReferenceJobFailed = false;
      this.hasProjectJobFailed = false;
      this.linkExtractionLoading = true;
      const intervalTime = this.type.value?.includes("video") ? 30000 : 5000;

      const treatError = (error?: string) => {
        clearInterval(checkInterval);
        this.linkExtractionLoading = false;
        this.hasReferenceJobFailed = true;
        this.hasProjectJobFailed = true;
        let errorMessage = "Algo deu errado na extração de dados.";

        if (error === "rate-limit" && showToast) {
          errorMessage = "Limite de tempo atingido ao tentar gerar conteúdo.";
          handleRuntimeErrors(errorMessage, { message: errorMessage });
        } else if (error === "resource-not-found" && showToast) {
          errorMessage = "Não foi possível extrair as informações da fonte fornecida.";
          handleRuntimeErrors(errorMessage, { message: errorMessage });
        } else if (
          error === "ResourceNotFoundError: The following resource was not found: Instagram url" &&
          showToast
        ) {
          errorMessage = "O link do vídeo do Instagram parece não estar correto. Verifique e tente de novo, por favor.";
          handleRuntimeErrors(errorMessage, { title: "Link do Instagram inválido", message: errorMessage });
        } else {
          handleRuntimeErrors(errorMessage, { message: errorMessage, showToast: showToast });
        }
      };

      if (!linkId) {
        treatError("resource-not-found");
      }

      const checkInterval = setInterval(async () => {
        const res = await apiService.get<ProjectData>({ endpoint: `teams/${teamId}/extract-data/${linkId}` });

        if (res.error) {
          clearInterval(checkInterval);
          treatError(res.error);
        }

        this.projectExtractedData = res.data;

        const extractedReferences: ExtractedData["jobs"]["references"] | undefined =
          this.projectExtractedData?.jobs?.references;
        const referencesFromSource = this.projectExtractedData?.jobs?.extractSourceData?.referencesFromSource;
        const hasExtractedDataFailed = this.projectExtractedData?.jobs.extractSourceData?.status === "errored";

        if (hasExtractedDataFailed) {
          treatError(this.projectExtractedData?.jobs.extractSourceData?.error);
        }

        if (extractedReferences && extractedReferences.status !== "pending") {
          const extractedData = extractedReferences?.dataExtracted;

          clearInterval(checkInterval);

          if (extractedReferences.status === "errored") {
            this.hasReferenceJobFailed = true;
            this.hasProjectJobFailed = true;
            this.linkExtractionLoading = false;

            if (this.type.value === "txt") {
              if (showToast) {
                toast.warning({
                  message:
                    "Não conseguimos extrair referências do documento enviado. Por favor, cadastre-as manualmente",
                  duration: 6000
                });
              }

              this.type.value = null;
              return;
            }

            if (showToast) {
              handleRuntimeErrors("Não foi possível extrair as informações da fonte fornecida.", {
                title: "Algo deu errado.",
                message: "Não foi possível extrair as informações da fonte fornecida."
              });
            }

            return;
          }

          if (!onboarding) {
            this.linkExtractionLoadingPercent = 100;
            this.linkExtractionLoading = false;
          }

          if (referencesFromSource) {
            this.addReferencesFromExtractedData(referencesFromSource);
          }

          this.addReferencesFromExtractedData(extractedData);

          if (createReferences) {
            await Promise.all(
              this.references.map(
                async (ref: { id: string; name: string; text: string; type: string; extractedDataId: string }) => {
                  const reference = await this.createReferenceRequest(
                    {
                      name: ref.name,
                      text: ref.text,
                      type: ref.type,
                      extractedDataId: ref.extractedDataId
                    },
                    teamId
                  );
                  const indexToUpdate = this.references.findIndex((r: { id: string }) => r.id === ref.id);
                  if (indexToUpdate !== -1 && !!reference.data?.id)
                    this.references[indexToUpdate].id = reference.data.id;
                  return reference;
                }
              )
            );
          }
        }
      }, intervalTime);
    },
    async addReferencesFromExtractedData(data?: {
      "video-script"?: (string | null)[];
      "post-captions"?: (string | null)[];
      cta?: (string | null)[];
      review?: (string | null)[];
      bullets?: (string | null)[];
      beneficios?: (string | null)[];
      h1?: string | null;
      h2?: string | null;
    }) {
      if (!data) return;

      Object.entries(data).forEach(([type, value]) => {
        // NOTE: Essa condicional cancela a adição de referências caso esteja vazia ou content "null"
        if (!value || !value.length || value.includes("null") || (Array.isArray(value) && value.includes(null))) return;

        const tempReference: Reference = {
          id: uuidv4(),
          type: type,
          name: getModelName(type),
          feedback: null,
          extractedDataId: this.extractedDataId || undefined,
          sourceType: this.type.value as "link" | "video-link" | "video" | "manual",
          source: this.type.content as string,
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString(),
          text: "",
          deleted: false
        };

        if (Array.isArray(value) && value.length > 0) {
          if (type === "post-captions") {
            value.forEach(item => {
              this.references.push({
                ...tempReference,
                text: item || ""
              });
            });
          } else {
            this.references.push({
              ...tempReference,
              text: value.join("\n")
            });
          }
        } else if (typeof value == "string") {
          this.references.push({
            ...tempReference,
            text: value
          });
        }
      });
    },
    async saveReference(ref: Reference) {
      return apiService.post({
        endpoint: `teams/${this.projectData?.teamId}/references`,
        body: {
          name: ref.name,
          text: ref.text,
          type: ref.type,
          extractedDataId: ref.extractedDataId || undefined
        }
      });
    },
    addReferenceSuccessToast(qtd: number) {
      switch (this.type.value) {
        case "link":
          return toast.success({
            title: "Referências por link adicionadas com sucesso",
            message: ` Você acabou de adicionar mais ${qtd} referência(s)!`
          });
        case "video-link":
        case "video":
          return toast.success({
            title: "Referências de video adicionada com sucesso",
            message: ` Você acabou de adicionar mais ${qtd} referência(s)!`
          });
      }
    },
    async saveApprovedReferences(teamId?: string, projectId?: string) {
      teamId = teamId || this.projectData?.teamId;
      this.fields.references.value = [];
      const references = this.references.filter((ref: { feedback: string | null }) => ref.feedback === "approved");
      if (references.length === 0) return;
      const refPromises = references.map(
        async (ref: { id: string; name: string; text: string; type: string; extractedDataId: string }) => {
          const res = await apiService.post<ReferenceResponse>({
            endpoint: `teams/${teamId}/references`,
            body: {
              name: ref.name,
              text: ref.text,
              type: ref.type,
              extractedDataId: ref.extractedDataId || this.extractedDataId || undefined
            }
          });
          if (!!res.data) {
            await this.updateProjectResources({ references: [res.data.id] }, projectId);
          }

          return res;
        }
      );
      try {
        this.referenceLoading = true;
        const responses = await Promise.all(refPromises);
        for (const res of responses) {
          if (res.error) {
            handleRuntimeErrors(res.error);
          }
          res.data && this.fields.references.value.push(res.data.id);
        }
        this.referenceLoading = false;

        return responses;
      } catch (err) {
        handleRuntimeErrors(err);
      }
      this.referenceLoading = false;
      return;
    },
    async appendApprovedReferencesToProject() {
      if (!this.fields.references.value.length) return;
      return await apiService.patch({
        endpoint: `projects/${this.projectData?.id}/add-resources`,
        body: {
          references: this.fields.references.value
        }
      });
    },
    async createProject(projectData: { name: string; teamId: string; users: string[]; onboarding?: boolean }) {
      this.projectLoading = true;

      const treatError = (error?: string) => {
        errorHandlerService.handleError(
          error,
          {
            "name-already-exists": {
              title: "Não foi possível criar o seu projeto",
              message: "Revise o campo do nome do projeto, esta informação deve ser única."
            }
          },
          undefined,
          true
        );
        this.projectLoading = false;
        this.fields.name.notAvailable = true;

        if (this.currentStep !== 1) this.changeCurrentStep(this.currentStep - 1);
        throw new Error(error);
      };

      const res = await apiService.post<Project>({ endpoint: "projects", body: projectData });

      this.projectLoading = false;

      if (res.error || !res.data) {
        treatError(res.error);
      }

      this.projectData = res.data;
      this.isProjectCreated = true;
      this.oldProjectname = res.data?.name ?? null;
      res.data?.id && localStorage.setItem("projectId", res.data?.id);
      localStorage.removeItem(this.PROJECT_NAME_STORAGE_KEY);
    },
    async updateProject(projectData: UpdateProjectDataType, projectId?: string, load = true) {
      const { userStatus } = useUser();
      const idProject =
        projectId || this.projectData?.id || localStorage.getItem("projectId") || userStatus.value.lastActiveProject.id;

      if (load) {
        this.projectLoading = true;
      }

      const treatError = (error?: string) => {
        errorHandlerService.handleError(
          error,
          {
            "name-already-exists": {
              title: "Não foi possível alterar o seu projeto",
              message: "Revise o campo do nome do projeto, esta informação deve ser única."
            }
          },
          undefined,
          true
        );
        this.projectLoading = false;
        this.fields.name.notAvailable = true;
        throw new Error(error);
      };

      if (this.oldProjectname === projectData.name) {
        this.projectLoading = false;
        return;
      }

      const res = await apiService.patch({ endpoint: `projects/${idProject}`, body: projectData });

      this.projectLoading = false;
      this.oldProjectname = this.fields.name.value ?? null;

      if (res.error) {
        treatError(res.error);
      }
    },
    async updateProjectResources(
      data: {
        brandVoices?: string[];
        tags?: string[];
        audiences?: string[];
        references?: string[];
      },
      projectId?: string
    ) {
      const { userStatus } = useUser();
      const idProject =
        projectId || this.projectData?.id || localStorage.getItem("projectId") || userStatus.value.lastActiveProject.id;

      const treatError = (error?: string) => {
        this.projectLoading = false;
        errorHandlerService.handleError(error);
      };

      const res = await apiService.patch({ endpoint: `projects/${idProject}/add-resources`, body: data });

      if (res.error) {
        treatError(res.error);
      }
      return res;
    },
    async removeProjectResources(
      data: {
        brandVoices?: string[];
        tags: string[];
        audiences?: string[];
        references?: string[];
      },
      projectId?: string
    ) {
      const idProject = projectId || this.projectData?.id;
      this.projectLoading = true;

      const treatError = (error?: string) => {
        this.projectLoading = false;
        handleRuntimeErrors(error, {
          title: "Erro!",
          message: "Algo deu errado, tente novamente."
        });
      };

      const res = await apiService.delete({ endpoint: `projects/${idProject}/remove-resources`, body: data });

      if (res.error) {
        treatError(res.error);
      }

      this.projectLoading = false;
    },
    async createProjectBrandVoice(teamId: string, brandVoiceData: ProjectBrandVoiceType, projectId?: string) {
      const idProject = projectId || this.projectData?.id;
      this.projectLoading = true;

      const treatError = (error?: string) => {
        this.projectLoading = false;
        error && errorHandlerService.handleError(error);
      };

      try {
        const res = await apiService.post<{ id: string }>({
          endpoint: `teams/${teamId}/brand-voices`,
          body: brandVoiceData
        });

        if (res.error || !res.data) {
          treatError(res.error);
        } else {
          await this.updateProjectResources({ brandVoices: [res.data.id] }, idProject);
        }
        this.projectLoading = false;

        this.projectLoading = false;
        this.nextStep();
      } catch (e) {
        treatError(e as string);
      }
    },
    async getProjectAudiences(teamId: string, ids: string[]) {
      this.projectLoading = true;

      const res = await apiService.get<Persona[]>({
        endpoint: `teams/${teamId}/audiences?ids=${ids.join(",")}`
      });

      this.projectLoading = false;

      if (res.error || !res.data) {
        res.error && errorHandlerService.handleError(res.error);
        return;
      }

      return res.data;
    },
    async createProjectAudiences(teamId: string, audienceData?: Persona, projectId?: string, audienceIds?: string[]) {
      const idProject = projectId || this.projectData?.id;
      this.projectLoading = true;

      if (audienceData) {
        try {
          let bodyData = { ...audienceData };

          const { id, gender, ...rest } = bodyData;
          id;

          bodyData = gender === "" ? (rest as Persona) : ({ gender, ...rest } as Persona);

          const res = await apiService.post<Persona>({
            endpoint: `teams/${teamId}/audiences`,
            body: bodyData
          });

          if (res.error || !res.data) {
            throw new Error();
          } else {
            await this.updateProjectResources({ audiences: [res.data.id] }, idProject);
          }
        } catch (e) {
          errorHandlerService.handleError(e);
          this.projectLoading = false;
          return;
        }
      } else if (audienceIds) {
        await this.updateProjectResources({ audiences: audienceIds }, idProject);
      }

      this.projectLoading = false;
    },
    async patchProjectAudiences(teamId: string, audienceId: string, body: Persona) {
      this.projectLoading = true;

      const res = await apiService.patch<Persona>({
        endpoint: `teams/${teamId}/audiences/${audienceId}`,
        body: body
      });

      this.projectLoading = false;

      if (res.error) {
        res.error && errorHandlerService.handleError(res.error, undefined, undefined, false);
        toast.error({
          title: "Não foi possível alterar a persona",
          message: "Por favor tente novamente. Se o problema persistir, entre em contato conosco."
        });
        return;
      } else {
        toast.success({
          title: "Persona alterada!",
          message: "As alterações foram salvas com sucesso."
        });
      }

      return res.data;
    },
    async deleteProjectAudiences(teamId: string, audienceId: string) {
      this.projectLoading = true;

      const res = await apiService.delete<Persona>({
        endpoint: `teams/${teamId}/audiences/${audienceId}`
      });

      this.projectLoading = false;

      if (res.error) {
        res.error && errorHandlerService.handleError(res.error);
        return;
      }

      return res.data;
    },
    async createReferenceRequest(
      reference: Pick<Reference, "name" | "text" | "type" | "extractedDataId">,
      teamId?: string
    ) {
      teamId = teamId || this.projectData?.teamId;
      const res = await apiService.post<{ id: string }>({
        endpoint: `teams/${teamId}/references`,
        body: {
          name: reference.name,
          text: reference.text,
          type: reference.type,
          extractedDataId: reference.extractedDataId || undefined
        }
      });
      return res;
    },
    async createReference(teamId: string, data: Reference) {
      try {
        const res = await apiService.post<Reference>({
          endpoint: `teams/${teamId}/references`,
          body: {
            name: data.name,
            text: data.text,
            type: data.type
          }
        });
        if (res.error || !res.data) {
          handleRuntimeErrors(res.error);
          return;
        }
        this.references.push({
          ...res.data
        });
      } catch (err) {
        handleRuntimeErrors(err);
      }
    },
    async updateUsers(user: User, action: "add" | "remove" = "add", projectId?: string) {
      const { userStatus } = useUser();
      const idProject = projectId || this.projectData?.id;
      const url = `teams/${userStatus.value?.activeTeam.id}/users/${user.email}`;

      const body: Record<string, unknown> = {
        projectId: idProject,
        removeFromProject: action !== "add"
      };

      const response = await apiService.patch({ endpoint: url, body });
      return response;
    },
    async removeMember(member: User) {
      try {
        const res = await this.updateUsers(member, "remove");
        if (res.error) {
          handleRuntimeErrors(res.error);
          return;
        }
        this.fields.members.value = this.fields.members.value.filter(
          (m: { email: string }) => m.email !== member.email
        );
      } catch (err) {
        handleRuntimeErrors(err);
      }
    },
    async addMember(member: User) {
      try {
        this.fields.members.value.push(member);
        const res = await this.updateUsers(member, "add");
        if (res.error) {
          handleRuntimeErrors(res.error);
          return;
        }
      } catch (err) {
        handleRuntimeErrors(err);
      }
    },
    async addMembers(members: User[]) {
      const { userStatus } = useUser();
      members = members.filter(member => member.email !== userStatus.value?.email);
      try {
        const reqArray = Array(members.length)
          .fill(0)
          .map(async (_, idx) => {
            const userToAdd = members[idx];
            return apiService.patch({
              endpoint: `teams/${this.projectData?.teamId}/users/${userToAdd?.email}`,
              body: {
                projectId: this.projectData?.id
              }
            });
          });

        const results = await Promise.all([...reqArray]);
        return results;
      } catch (err) {
        handleRuntimeErrors(err, {
          title: "Erro",
          message: "Um erro, inesperado ocorreu"
        });
      }
      return;
    },
    async removeTagFromTeam(teamId: string, tagId: string) {
      const projectStore = useProjectStore();
      let hasTag = false;

      for (const project of projectStore.projects) {
        project.tags.forEach(tag => {
          if (tag.id === tagId) {
            hasTag = true;
          }
        });
        if (hasTag) break;
      }
      if (!hasTag) {
        await apiService.delete({ endpoint: `teams/${teamId}/tags/${tagId}` });
      }
    },
    async addTags(tags: ITag[], addToProject = true, projectId?: string) {
      const { userStatus } = useUser();
      const idProject = projectId || this.projectData?.id;

      const promisesArray = tags.map(async tag => {
        // NOTE: Caso a tag já exista, apenas adiciona ao projeto
        if (tag.id && addToProject) {
          await this.updateProjectResources({ tags: [tag.id] }, idProject);
          return;
        }
        const res = await apiService.post<{ id: string }>({
          endpoint: `teams/${userStatus.value?.activeTeam.id}/tags`,
          body: {
            color: tag.color,
            name: tag.text
          }
        });

        if (res.error || !res.data) return handleRuntimeErrors("Algo deu errado.");

        if (addToProject) {
          await this.updateProjectResources({ tags: [res.data.id] }, idProject);
        }
      });

      const responses = await Promise.all(promisesArray);
      return responses;
    },
    async getTags(teamId: string, tagId?: string) {
      if (!tagId) {
        tagId = "";
      } else {
        tagId = "/" + tagId;
      }

      const res = await apiService.get({ endpoint: `teams/${teamId}/tags${tagId}` });
      return res;
    },
    async getAudiences(teamId: string, audienceId?: string) {
      const res = await apiService.get<Persona>({
        endpoint: `teams/${teamId}/audiences${audienceId && "/" + audienceId}`
      });
      return res;
    },
    async getBrandVoices(teamId: string, brandVoiceId?: string) {
      const res = await apiService.get<ProjectBrandVoiceType>({
        endpoint: `teams/${teamId}/brand-voices${brandVoiceId && "/" + brandVoiceId}`
      });
      return res;
    },
    async getReferences(teamId: string, referenceId?: string) {
      const res = await apiService.get<ReferenceResponse>({
        endpoint: `teams/${teamId}/references${referenceId && "/" + referenceId}`
      });
      return res;
    },
    async getExtratectedData(teamId: string, extractedDataId: string) {
      const res = await apiService.get<ExtractedData>({ endpoint: `teams/${teamId}/extract-data/${extractedDataId}` });
      return res;
    },
    async updateBrandVoices(
      brandVoiceId: string,
      data: {
        tones?: string[];
        languages?: string[];
        brandVoices?: string[];
        perspectives?: string[];
      },
      teamId?: string
    ) {
      const { userStatus } = useUser();
      const idTeam = teamId || userStatus.value?.activeTeam.id;

      const res = await apiService.patch({ endpoint: `teams/${idTeam}/brand-voices/${brandVoiceId}`, body: data });
      return res;
    },
    async updateReference(
      teamId: string | undefined,
      referenceId: string,
      data: { name: string; text: string; type: string }
    ) {
      const { userStatus } = useUser();
      const idTeam = teamId || userStatus.value?.activeTeam.id;

      const res = await apiService.patch({ endpoint: `teams/${idTeam}/references/${referenceId}`, body: data });

      return res;
    },
    async updateTag(data: { color: TagColorValue; name: string }, tagId: string, teamId?: string) {
      const teamStore = useTeamStore();

      const { userStatus } = useUser();
      teamId = teamId || userStatus.value?.activeTeam.id;

      const res = await apiService.patch({ endpoint: `teams/${teamId}/tags/${tagId}`, body: data });
      if (!res.error) {
        teamStore.updateTeamTags(tagId, data);
      }

      return res;
    },
    async removeResource(
      resource: "audiences" | "brandVoices" | "references" | "tags",
      projectId: string,
      resourceIds: string[]
    ) {
      const res = await apiService.delete({
        endpoint: `projects/${projectId}/remove-resources`,
        body: {
          [resource]: resourceIds
        }
      });

      return res;
    },
    async deleteReference(teamId: string, referenceId: string) {
      return await apiService.delete({ endpoint: `teams/${teamId}/references/${referenceId}` });
    }
  },
  getters: {
    phases: () => phases,
    currentPhase: state => steps[state.currentStep],
    phasesFields(state: ProjectCreationState): ProjectPhase[] {
      const phasesStepsList = phases.map((phase, index): ProjectPhase => {
        const fields = Object.values(state.fields);

        const filteredPhase = {
          ...phase,
          status: "disabled",
          fields: fields.filter(f => f.phase === index)
        };

        let phaseStatus: ProjectStatus = "disabled";
        for (const field of filteredPhase.fields) {
          if (field.status === "completed") {
            phaseStatus = "completed";
          } else if (field.status === "pending") {
            phaseStatus = "pending";
            break;
          }
        }

        return { ...filteredPhase, status: phaseStatus };
      });
      return phasesStepsList;
    }
  }
});
