export const validateSourceErrors = {
  "insufficient-words": {
    title: "Pouca informação",
    message:
      "O conteúdo enviado contém pouca informação para realizar o treinamento. Por favor, tente novamente com outro material com no mínimo 300 palavras."
  },
  "resource-not-found": {
    title: "Link inválido",
    message: "Este parece ser um link inválido. Por favor, confira e tente novamente com outro link."
  },
  "forbidden-resource": {
    title: "Link não permitido",
    message: "O provedor do site não permite que realizemos a leitura. Por favor, tente novamente com outro link."
  },
  "team-is-not-active": {
    title: "Esse recurso está bloqueado.",
    message: "Por favor, verifique o status de sua equipe ou entre em contato com o suporte."
  },
  "invalid-url": {
    title: "A URL fornecida não é válida.",
    message: "Por favor, verifique e tente novamente."
  },
  "not-enough-content": {
    title: "Não consigo extrair informações necessárias.",
    message: "O link deve conter texto com mais de 1000 caracteres."
  },
  "invalid-youtube-video-url": {
    title: "URL do YouTube Inválida",
    message: "Confira a URL do YouTube e tente novamente. Estamos quase lá!"
  },
  "internal-server-error": {
    title: "Erro interno",
    message:
      "Estamos trabalhando para resolver isso. Por favor tente novamente mais tarde. Se o problema persistir, entre em contato conosco."
  },
  "invalid-instagram-video-url": {
    title: "Link do Instagram inválido",
    message: "O link do vídeo do Instagram parece não estar correto. Verifique e tente de novo, por favor."
  },
  "invalid-tiktok-video-url": {
    title: "Link do TikTok inválido",
    message: "O link do vídeo do TikTok parece não estar correto. Verifique e tente de novo, por favor."
  },
  default: {
    title: "Algo deu errado.",
    message:
      "Oops, não foi possível extrair os dados do link. Por favor, verifique os requisitos do link e tente novamente."
  }
};
