// Composables
import { useModelsConfig } from "@/composables/useModelsConfig";

// Types
import { ModelSchema } from "@/@types/models";

const { config } = useModelsConfig();

export const getModelName = (modelType: string) => {
  const modelsName: Record<string, string> = {
    "aida-v2": "AIDA",
    "pas-v2": "PAS",
    "fbads-v2": "Facebook Ads",
    "name-v2": "Nome do Produto",
    "post-captions-v2": "Legendas de posts",
    "continue-v2": "Continuar/Expandir texto...",
    gads: "Anúncio do Google",
    "gads2-v2": "Google Ads - Pesquisa",
    "pmax-v2": "Google Ads - Pmax",
    "email-vendas-v2": "E-mail de vendas",
    "bab-v2": "BAB",
    "pppp-v2": "PPPP",
    "generate-prompt-v2": "Sugestão da IA",
    "assunto-email": "Assunto de e-mail",
    "review-v2": "Avaliações",
    "summary-v2": "Resumir texto",
    "blog-ideas": "Ideias para blog",
    "blog-title": "Título para artigo",
    "blog-post": "Esboço de Blog Post",
    "blog-intro": "Introdução",
    "blog-conclusao": "Conclusão",
    "blog-topics": "Seção para artigo",
    "writer-blog-post-v3": "Parágrafo para seção",
    "writer-blog-intro-v2": "Introdução de Artigo",
    "writer-blog-intro-v2-seo": "Introdução de Artigo com SEO",
    "writer-blog-title": "Título de Artigo",
    "writer-blog-title-v2-seo": "Título de Artigo com SEO",
    "writer-blog-post-v2-seo": "Tópico para Artigo com SEO",
    "writer-blog-post-v2": "Tópico para Artigo",
    "writer-blog-title-v2": "Título de Artigo",
    "writer-blog-conclusion-v2": "Conclusão de Artigo",
    "writer-blog-topics-v2": "Tópicos de Artigo",
    "writer-blog-conclusion-v2-seo": "Conclusão de Artigo com SEO",
    "beneficios-v2": "Benefícios",
    "beneficios-caracteristicas": "Características para benefícios",
    "video-desc-v2": "Descrição",
    "video-script-v2": "Script",
    "video-title-v2": "Título",
    cta: "CTA",
    h2: "Subheadline",
    "bullets-v2": "Tópicos persuasivos",
    "email-welcome-v2": "Boas-vindas",
    "email-cold-v2": "E-mail de Prospecção",
    "tiktok-v2": "Conteúdo para Vídeos",
    comments: "Respostas de comentários",
    ideas: "Ideias de posts",
    "keywords-v2": "Extrator de palavras chave",
    "email-cart-v2": "Carrinho abandonado",
    story: "História criativa",
    "improver-v2": "Melhorar/Reescrever texto",
    "desc-v2": "Descrição",
    "h1-v2": "Título",
    rewrite: "Reescritor de conteúdo",
    "text-plus": "Expansor de texto",
    mission: "Missão",
    "slogan-v2": "Slogan",
    values: "Valores",
    "seo-v2": "Título e Descrição para SEO",
    "ideias-ridiculous-v2": "Ideias ridículas de Marketing",
    questions: "Perguntas envolventes",
    "bio-insta-v2": "Bio do Instagram",
    h1tab: "Headlines NativeAds",
    "esboço-blog-post": "Esboço para Blog Post",
    "ideas-plano-mkt-v2": "Plano de Marketing",
    "ytads-v2": "Youtube Ads",
    "discovery-v2": "Google Ads - Discovery",
    "linkedin-enquete": "LinkedIn - Enquete",
    "linkedin-recomendacoes": "Linkedin - Recomendações",
    "linkedin-ads-v2": "LinkedIn Ads",
    "linkedin-v2": "Post LinkedIn",
    "twitter-v2": "Post Twitter",
    "cron-social-v2": "Cronograma de Redes Sociais",
    "whatsapp-prospeccao-v2": "Whatsapp",
    "email-prospeccao-1": "E-mail Prospecção Primeira Etapa - Conscientização",
    "email-prospeccao-2-v2": "Funil de e-mail",
    "coldcall-prospeccao-vendas": "Coldcall (ligação) Prospecção/Vendas",
    "ligacao-prospeccao-v2": "Coldcall",
    "video-creative-v2": "Ideias para criativo vídeo",
    "copy-creative-v2": "Ideias para criativo imagem",
    "post-instagram-v2": "Ideias de posts",
    "vsl-jon-benson-step-9": "Jon Benson etapa 9",
    "vsl-jon-benson-step-8": "Jon Benson etapa 8",
    "vsl-jon-benson-step-7": "Jon Benson etapa 7",
    "vsl-jon-benson-step-6": "Jon Benson etapa 6",
    "vsl-jon-benson-step-5": "Jon Benson etapa 5",
    "vsl-jon-benson-step-4": "Jon Benson etapa 4",
    "vsl-jon-benson-step-3": "Jon Benson etapa 3",
    "vsl-jon-benson-step-2": "Jon Benson etapa 2",
    "vsl-jon-benson-step-1": "Jon Benson etapa 1",
    "carrossel-v2": "Carrossel",
    "stories-cronogram-v2": "Cronograma de Stories",
    "email-ideas-v2": "Ideias de assuntos",
    "email-payment-v2": "Confirmação de Pagamento",
    "email-upsell-v2": "Oferta de Upsell",
    "email-reengagement-v2": "Reengajamento",
    "email-satisfaction-v2": "Pesquisa de Satisfação",
    "email-demand-v2": "Livre sob demanda",
    "landing-page-sell-v2": "Vendas",
    "landing-page-soon-v2": "Em Breve",
    "landing-page-product-v2": "Informação do Produto",
    "landing-page-leads-v2": "Captura de Leads",
    "landing-page-thankyou-v2": "Obrigado",
    "cadence-email-cart-abandoned-v2": "Fluxo para carrinho abandonado",
    "cadence-email-sales-v2": "Fluxo para e-mail de vendas",
    "cadence-email-upsell-v2": "Fluxo para oferta de Upsell",
    "lead-crawler:email": "Script de e-mail para lead",
    "lead-crawler:cold-call": "Script de ligação para lead",
    "lead-crawler:whatsapp": "Script de Whatsapp para lead",
    "lead-crawler:email-v2": "Script de e-mail para lead",
    "lead-crawler:cold-call-v2": "Script de ligação para lead",
    "lead-crawler:whatsapp-v2": "Script de Whatsapp para lead",
    "docbase:command-empty-doc:use-project": "Docbase",
    "docbase:command-empty-doc": "Docbase",
    "docbase:command:use-project": "Docbase",
    "docbase:command": "Docbase",
    "docbase:command-selection": "Docbase",
    "docbase:command-selection:use-project": "Docbase",
    "docbase:command-empty-doc:use-project-v2": "Docbase",
    "docbase:command-empty-doc-v2": "Docbase",
    "docbase:command:use-project-v2": "Docbase",
    "docbase:command-v2": "Docbase",
    "docbase:command-selection-v2": "Docbase",
    "docbase:command-selection:use-project-v2": "Docbase",
    "blog-post-title-ideas-v2": "Ideias para título de blog post",
    command: "Docbase"
  };
  return modelsName[modelType] || modelType;
};

export const filterModels = (models: ModelSchema[], ids: string[]) => {
  return models.filter(model => ids.includes(model.id));
};

export const getAllModelsFromConfig = () => {
  let result: ModelSchema[] = [];
  Object.values(config.value).forEach(model => {
    result = [...result, ...model.models];
  });

  return result;
};
